.wysiwygInputEditor {
    border: 1px solid #e9e9e9;
    padding: 0 1rem;
}

.wysiwygEditorToolbar {
    border: 1px solid #e9e9e9;
}

.rdw-option-wrapper {
    border: 1px solid #e9e9e9;
}
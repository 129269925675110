.MuiButton-contained,
.MuiButton-outlined {
  text-transform: uppercase;
  box-shadow: none !important;

  &.MuiButton-containedPrimary,
  &.MuiButton-outlinedPrimary {
    font-size: 1rem;
    line-height: 1.3rem;
    font-weight: 400;
    padding: 0.6rem 2.6rem;
    border-radius: 0.15rem;
  }
}

// primary outlined button
.MuiButton-outlined {
  &.MuiButton-outlinedPrimary {
    border: 2px solid $primary-color;
    color: $dark-text;

    &:hover,
    &:focus,
    &:active {
      color: $white-color;
      background-color: $primary-color;
      border: 2px solid $primary-color;
    }
  }
}

// primary contained button
.MuiButton-contained {
  &.MuiButton-containedPrimary,
  &.Mui-focusVisible {
    background-color: $primary-color;
    border: 2px solid $primary-color;

    // set full width of button in grid/form
    &.full-width {
      width: 100%;
      padding-left: 0px;
      padding-right: 0px;
    }

    &:hover,
    &:focus,
    &:active {
      border: 2px solid $primary-color;
      color: $dark-text;
      background-color: $white-color;
    }

    &.loading-button {
      padding: 0.6rem 1.3rem;
    }
  }
}

// loader for the button in registration form
.loader {
  padding-right: 1.3rem;
  width: 1.3rem;
  height: 1.3rem;

  .MuiCircularProgress-colorPrimary {
    color: $white-color;
    width: 1.3rem !important;
    height: 1.3rem !important;
  }
}

.MuiInputBase-root.MuiInputBase-adornedEnd {
  .MuiIconButton-root {
    padding: 2px;
  }
}

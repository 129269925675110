$primary-color: #e20074;
$primary-color-lighter: lighten($primary-color, 20);
$required-color: #f44336;
$done-color: #10ae3f;
$white-color: #ffffff;

$light-text: #999999;

$grey-color: #707070;

$dark-text: #5a5a5a;
$darker-text: #222222;

//$body-background-color: #eeeeee;
$body-background-color: #f5f5f5;

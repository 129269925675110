@font-face {
  font-family: 'TeleNeo';
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  src: url('../font/TeleNeoWeb-Thin.eot'); /* IE9 Compat Modes */
  src: url('../font/TeleNeoWeb-Thin.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../font/TeleNeoWeb-Thin.woff2') format('woff2'), /* Super Modern Browsers */
  url('../font/TeleNeoWeb-Thin.woff') format('woff'); /* Pretty Modern Browsers */
}

@font-face {
  font-family: 'TeleNeo';
  font-weight: 300;
  font-style: italic;
  font-display: swap;
  src: url('../font/TeleNeoWeb-ThinItalic.eot'); /* IE9 Compat Modes */
  src: url('../font/TeleNeoWeb-ThinItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../font/TeleNeoWeb-ThinItalic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../font/TeleNeoWeb-ThinItalic.woff') format('woff'); /* Pretty Modern Browsers */
}

@font-face {
  font-family: 'TeleNeo';
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url('../font/TeleNeoWeb-Regular.eot'); /* IE9 Compat Modes */
  src: url('../font/TeleNeoWeb-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../font/TeleNeoWeb-Regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../font/TeleNeoWeb-Regular.woff') format('woff'); /* Pretty Modern Browsers */
}
@font-face {
  font-family: 'TeleNeo';
  font-weight: 400;
  font-style: italic;
  font-display: swap;
  src: url('../font/TeleNeoWeb-RegularItalic.eot'); /* IE9 Compat Modes */
  src: url('../font/TeleNeoWeb-RegularItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../font/TeleNeoWeb-RegularItalic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../font/TeleNeoWeb-RegularItalic.woff') format('woff'); /* Pretty Modern Browsers */
}

@font-face {
  font-family: 'TeleNeo';
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: url('../font/TeleNeoWeb-Medium.eot'); /* IE9 Compat Modes */
  src: url('../font/TeleNeoWeb-Medium.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../font/TeleNeoWeb-Medium.woff2') format('woff2'), /* Super Modern Browsers */
  url('../font/TeleNeoWeb-Medium.woff') format('woff'); /* Pretty Modern Browsers */
}
@font-face {
  font-family: 'TeleNeo';
  font-weight: 500;
  font-style: italic;
  font-display: swap;
  src: url('../font/TeleNeoWeb-MediumItalic.eot'); /* IE9 Compat Modes */
  src: url('../font/TeleNeoWeb-MediumItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../font/TeleNeoWeb-MediumItalic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../font/TeleNeoWeb-MediumItalic.woff') format('woff'); /* Pretty Modern Browsers */
}

@font-face {
  font-family: 'TeleNeo';
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url('../font/TeleNeoWeb-Bold.eot'); /* IE9 Compat Modes */
  src: url('../font/TeleNeoWeb-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../font/TeleNeoWeb-Bold.woff2') format('woff2'), /* Super Modern Browsers */
  url('../font/TeleNeoWeb-Bold.woff') format('woff'); /* Pretty Modern Browsers */
}
@font-face {
  font-family: 'TeleNeo';
  font-weight: 700;
  font-style: italic;
  font-display: swap;
  src: url('../font/TeleNeoWeb-BoldItalic.eot'); /* IE9 Compat Modes */
  src: url('../font/TeleNeoWeb-BoldItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../font/TeleNeoWeb-BoldItalic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../font/TeleNeoWeb-BoldItalic.woff') format('woff'); /* Pretty Modern Browsers */
}

@font-face {
  font-family: 'TeleNeo';
  font-weight: 900;
  font-style: normal;
  font-display: swap;
  src: url('../font/TeleNeoWeb-ExtraBold.eot'); /* IE9 Compat Modes */
  src: url('../font/TeleNeoWeb-ExtraBold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../font/TeleNeoWeb-ExtraBold.woff2') format('woff2'), /* Super Modern Browsers */
  url('../font/TeleNeoWeb-ExtraBold.woff') format('woff'); /* Pretty Modern Browsers */
}
@font-face {
  font-family: 'TeleNeo';
  font-weight: 900;
  font-style: italic;
  font-display: swap;
  src: url('../font/TeleNeoWeb-ExtraBoldItalic.eot'); /* IE9 Compat Modes */
  src: url('../font/TeleNeoWeb-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../font/TeleNeoWeb-ExtraBoldItalic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../font/TeleNeoWeb-ExtraBoldItalic.woff') format('woff'); /* Pretty Modern Browsers */
}

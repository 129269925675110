@mixin sm-width {
  @media (min-width: $sm-width) {
    @content;
  }
}

@mixin md-width {
  @media (min-width: $md-width) {
    @content;
  }
}

@mixin lg-width {
  @media (min-width: $lg-width) {
    @content;
  }
}

@mixin xl-width {
  @media (min-width: $xl-width) {
    @content;
  }
}

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin bold-text {
  color: $dark-text;
  font-weight: 700;
}

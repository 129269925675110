.licence-form {
  &.MuiCard-root {
    padding-left: 40px !important;
    padding-right: 40px !important;

    @include lg-width {
      padding-left: 80px !important;
      padding-right: 80px !important;
    }

    @include xl-width {
      padding-left: 120px !important;
      padding-right: 120px !important;
    }
  }

  .MuiCardHeader-root {
    padding: 0 !important;
  }

  .MuiCardContent-root {
    p.length-switch {
      margin: 0 0 3rem;
      text-align: center;
      color: $dark-text;

      // styles to tweak the switcher to be symmetric
      .MuiSwitch-root {
        width: 57px;
        padding-left: 13px;
      }

      .MuiSwitch-colorSecondary {
        color: $white-color;

        & + .MuiSwitch-track {
          background-color: $primary-color;
          transform: scale(1.5, 1.5);
          opacity: 1;
          width: 33px;
          height: 17px;
          position: relative;
          top: -1px;
          border-radius: 8px;
        }

        .MuiSwitch-thumb {
          box-shadow: none;
        }
      }
    }

    .licence {
      cursor: pointer;
      width: 100%;
      height: 400px;
      background-color: $white-color;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
      box-sizing: border-box;

      &.selected {
        -webkit-box-shadow: 0px 0px 12px 1px rgba(133, 133, 133, 0.6);
        -moz-box-shadow: 0px 0px 12px 1px rgba(133, 133, 133, 0.6);
        box-shadow: 0px 0px 12px 1px rgba(133, 133, 133, 0.6);

        header {
          background-color: $primary-color;
          color: $white-color;
        }
      }

      .recommendation {
        color: $white-color;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 3.6px;
        @include flex-center;
        font-weight: 400;
        position: absolute;
        top: -24px;
        height: 24px;
        width: 100%;
        background-color: $done-color;
      }

      header {
        height: 3.5rem;
        font-size: 1.25rem;
        font-weight: 500;
        border: 3px solid $primary-color;
        color: $primary-color;
        @include flex-center;
      }

      main {
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        border-left: 3px solid $primary-color;
        border-right: 3px solid $primary-color;

        div {
          width: 100%;
          box-sizing: border-box;
          text-align: center;
          padding: 12px 6px;
          font-size: 0.75rem;
          color: $darker-text;
          position: relative;

          &::after {
            content: '';
            position: absolute;
            left: 5%;
            bottom: 0;
            height: 1px;
            width: 90%;
            border-bottom: 1px solid $grey-color;
          }

          &:last-child::after {
            content: none;
          }
        }
      }

      footer {
        height: 2.5rem;
        font-weight: 700;
        background-color: $primary-color;
        border-top: 3px solid $primary-color;
        @include flex-center;

        p {
          box-sizing: border-box;
          margin: 0;
          padding: 8px 6px;
          height: 100%;
          width: 100%;
          font-size: 1rem;
          color: $white-color !important;
          line-height: 1 !important;

          &.individual {
            font-size: 0.7rem !important;
            font-weight: 400 !important;
            @include flex-center;
            flex-direction: column;

            a {
              color: $white-color;
            }
          }

          sup,
          sub {
            font-size: 0.8rem;
            font-weight: 400;
          }
        }
      }
    }
  }
}

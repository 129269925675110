.registration-grid {
  display: grid;
  height: 100vh;
  grid-auto-rows: 120px auto 56px;

  &.empty-header {
    grid-auto-rows: 56px auto 56px;
  }

  // HEADER PART WITH STEPPER OR JUST PLACE HOLDER IN GRID FOR DESIGN
  .grid-header {
    background-color: transparent;

    header {
      height: 100%;
      margin: auto;

      @include md-width {
        width: 840px;
      }

      @include lg-width {
        width: 1040px;
      }

      @include xl-width {
        width: 1240px;
      }

      .MuiPaper-root {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        padding: 0px;
        height: 100%;
        background-color: transparent;

        .MuiStep-root {
          padding: 8px;

          &.done-step {
            .MuiStepIcon-root {
              color: $primary-color-lighter;
              cursor: pointer;

              .MuiStepIcon-text {
                fill: $white-color;
              }
            }
          }

          .MuiStepIcon-root {
            color: $white-color;
            width: 40px;
            height: 40px;

            &.MuiStepIcon-completed {
              color: $done-color;
              cursor: pointer;

              &:hover {
                color: transparentize($done-color, 0.2);
              }
            }

            &.MuiStepIcon-active {
              color: $primary-color;
              cursor: default;

              .MuiStepIcon-text {
                fill: $white-color;
              }
            }

            .MuiStepIcon-text {
              fill: $light-text;
              font-weight: 700;
              font-size: 12px;
            }
          }
        }

        .MuiStepLabel-label {
          margin-top: 12px;
          color: $darker-text;
          font-weight: 400;
          font-size: 1rem;
        }

        .MuiStepLabel-active {
          font-weight: 700;
          color: $primary-color;
        }

        // line between step circles centered
        .MuiStepConnector-alternativeLabel {
          padding: 0 14px;
          top: 28px;

          .MuiStepConnector-line {
            border-color: #d9d8d8;
          }
        }
      }
    }
  }

  // CONTENT PART OF THE REGISTRATION PAGE
  .grid-main {
    height: 100%;
    background-color: transparent;

    main {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      height: 100%;

      &.empty-header {
        align-items: center;
      }

      .payment-form {
        color: $darker-text;

        &.MuiCard-root .MuiCardContent-root .MuiGrid-container p:not(.Mui-error) {
          font-size: 1rem;
          line-height: 1.3;
          margin: 8px 0;
        }

        // on boarding payment page
        .text-align-left {
          p {
            text-align: left !important;
            font-size: 1rem;
          }
        }

        .text-align-right {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          justify-content: flex-end;

          p {
            text-align: right !important;
            line-height: 43px;

            &.summary {
              font-weight: 700;
              font-size: 1.2rem !important;
              color: $darker-text;

              span.price {
                font-size: 1.6rem;
              }
            }
          }
        }
      }

      .MuiCard-root {
        border-radius: 6px;
        box-shadow: 0px 1px 2px #0000001a;
        margin: 1rem 0;
        box-sizing: border-box;
        width: 640px;
        padding: 20px 40px;

        // card with payment card informations
        &.payment {
          box-shadow: 0px 3px 10px #2222224d;
          padding: 20px 80px;
          border-radius: 3px;
          margin: 8px 8px 0;

          .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-1 {
            padding: 0;

            svg {
              fill: $primary-color;
              color: $white-color;
              width: 100%;
              height: 100%;
            }
          }
        }

        // header of the card
        .MuiCardHeader-root {
          text-align: center;
          color: $darker-text;
          padding: 0 0 20px;

          .MuiCardHeader-title {
            font-weight: 500;
            display: flex;
            flex-direction: column;
            align-items: center;
            color: $dark-text;

            svg {
              // this will be replaced with icon with a badge
              width: 144px;
              height: 88px;
            }

            p {
              font-size: 1rem;
              font-weight: 400;
            }
          }
        }

        .MuiCardContent-root {
          padding: 0;

          .MuiGrid-container {
            padding: 0;

            // paragraphs in card excluding the error messages
            p:not(.Mui-error) {
              max-width: 768px;
              color: $darker-text;
              font-size: 1.2rem;
              line-height: 1.6rem;
              text-align: center;

              &.support {
                font-size: 0.9rem;
                line-height: 1.2rem;
                padding: 60px 0 40px;
                margin: 0;
              }
            }
          }
        }

        // main part of card with buttons
        .MuiCardActions-root {
          padding: 20px 0 0;
        }
      }

      // media queries for padding around the card,
      // under the header and above the button
      @include md-width {
        .MuiCard-root {
          padding: 20px 80px;
          width: 840px;

          .MuiCardHeader-root {
            padding: 0 0 20px;
          }

          .MuiCardActions-root {
            padding: 20px 0 0;
          }
        }
      }

      @include lg-width {
        .MuiCard-root {
          padding: 40px 160px;
          width: 1040px;

          &.payment {
            padding: 30px 100px;
          }

          .MuiCardHeader-root {
            padding: 0 0 40px;

            .MuiCardHeader-title p {
              margin-top: 30px;
            }
          }

          .MuiCardActions-root {
            padding: 40px 0 0;
          }
        }
      }

      @include xl-width {
        .MuiCard-root {
          padding: 60px 220px;
          width: 1240px;

          &.payment {
            padding: 40px 120px;
          }

          .MuiCardHeader-root {
            padding: 0 0 60px;

            .MuiCardHeader-title p {
              margin-top: 40px;
            }
          }

          .MuiCardActions-root {
            padding: 60px 0 0;
          }
        }
      }
    }
  }
}

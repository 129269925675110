//html {
//  font-size: 14px;
//}

body {
  margin: 0;
  height: 100vh;
  font-family: 'TeleNeo', sans-serif !important;
  background-color: $body-background-color;
}

// gradients
.gradient {
  position: absolute;
  height: 100%;
  width: 100%;
  pointer-events: none;
  background-color: rgba(255, 255, 255, 0);
}

// watermark icon in the left bottom of page
.watermark-icon {
  .MuiSvgIcon-root {
    fill: #d9d8d8;
    position: fixed;
    width: 32rem;
    height: 32rem;
    bottom: -8rem;
    left: -8rem;
    z-index: -1;
  }
}
